.home-page-row-detail{
    &__main{
        padding: 2em 2em;
		width: 100%;
		height: 100vh;
		grid-column: 2/3;
		overflow-y: auto;
        &__container{
            padding: 1em 1em 2em;
            margin-bottom: 2em;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
        }
    }
    &__hintText{
        color: grey;
        font-size: 0.9rem;
        font-family: var(--font-medium);
        margin-top: 5px;    
    }
    &__chip{
        &-container{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 30px;
        }
    }
}