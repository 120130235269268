html,
body {
	margin: 0;
	font-family: var(--font-medium);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "Visby-Regular";
	src: local("Visby-Regular"),
		url(Assets/Fonts/Visby-Regular.otf) format("opentype");
}

@font-face {
	font-family: "Visby-Medium";
	src: local("Visby-Medium"),
		url(Assets/Fonts/Visby-Medium.otf) format("opentype");
}

@font-face {
	font-family: "JosefinSans-Regular";
	src: local("JosefinSans-Regular"),
		url(Assets/Fonts/JosefinSans-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "JosefinSans-Semibold";
	src: local("JosefinSans-Semibold"),
		url(Assets/Fonts/JosefinSans-Semibold.ttf) format("truetype");
}

p,
div,
img,
a,
h1,
h2,
h3,
h4,
h5,
span {
	padding: 0;
	margin: 0;
	text-decoration: none;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	height: 100%;
	--font-regular: Visby-Regular, sans-serif;
	--font-medium: Visby-Medium, sans-serif;
	--font-semi-jose: JosefinSans-Semibold, san-serif;
	--font-regular-jose: JosefinSans-Regular, san-serif;
	--primary-color: #004032;
	--accent-text: #a7a7a7;
	--secondary-color: #fffefc;
	--accent-color: #eff8f0;

	--transition-bg: background-color 200ms ease-in-out;
	--transition-color: color 200ms ease-in-out;
	--shadow-inset: 0 -10px 6px 0 rgba(213, 213, 213, 0.88) inset;
	--shadow: 0 1px 6px 0 rgba(213, 213, 213, 0.88);
	--border-primary: 1px solid var(--primary-color);
	--modal-bg: #fdf3e8;
}

html,
body,
#root,
.App {
	height: 100%;
	overflow: visible;
}

.w-100 {
	width: 100%;
}

.card-animation {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.spinner-container {
	display: grid;
	place-items: center;
	height: 100%;
}

.h-100 {
	height: 100%;
}

.h-50 {
	height: 50%;
}

.h-25 {
	height: 25%;
}

.h-90 {
	height: 90%;
}

.h-auto {
	height: auto;
}

.d-flex {
	display: flex;
}
.d-flex-column {
	display: flex;
	flex-direction: column;
}

.justify-content-between-start {
	align-items: start;
	justify-content: space-between;
}

.justify-content-between {
	align-items: center;
	justify-content: space-between;
}

.justify-content-evenly {
	align-items: center;
	justify-content: space-evenly;
}

.justify-content-center {
	align-items: center;
	justify-content: center;
}

.justify-content-start{
	align-items: center;
	justify-content: start;
}

.w-20 {
	width: 20%;
}
.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.w-60 {
	width: 75%;
}

.w-75 {
	width: 75%;
}
.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}

.w-auto {
	width: auto;
}

.margin-auto {
	margin: 0 auto;
}

.container-main {
	padding: 2em 2em;
	width: 100%;
	grid-column: 2/3;
	height: 100%;
	overflow: visible;
}

.container-box {
	width: 100%;
	background-color: white;
	margin: 2em 0 0;
	border: 1px solid var(--primary-color);
	padding: 0.5em 2em;
	min-height: 90%;
}

.box-shadow-sm {
	box-shadow: var(--shadow-inset);
}

.btn-list {
	color: var(--primary-color);
	font-size: 1.2rem;
	font-family: var(--font-medium);
	cursor: pointer;
	background-color: transparent;
	border: none;
	margin: 0;
	align-self: center;
	/* padding: 0 0 0 2em; */
	outline: none;
	align-self: center;
	width: 4rem;
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
}

.Modal__main {
	position: absolute;
	outline: none;
	overflow-y: auto;
	background-color: var(--modal-bg);
	padding: 2em;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.close {
	display: grid;
	justify-items: flex-end;
	align-items: center;
	width: 100%;
}

.close__img {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}

.label {
	font-size: 1.3rem;
	font-family: var(--font-medium);
	margin: 1em 0;
	text-align: left;
	color: var(--accent-text);
}

textarea {
	background-color: #ffffff;
	border: 1px solid #b7b7b7;
	font-size: 1rem;
	padding: 0.8em 1em;
	margin: 0;
	outline: none;
	font-family: var(--font-medium);
	width: 100%;
	border-radius: 0.4em;
}

textarea:focus {
	border: 1px solid #2c2c2c;
}

.MuiMenuItem-root,
.MuiInputBase-root,
.MuiSelect-root,
.MuiInputBase-input,
.MuiFormLabel-root {
	font-family: var(--font-medium) !important;
}

.MuiOutlinedInput-input {
	padding: 0.9em 14px !important;
}

.flex-start {
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

.MuiSelect-root,
.MuiMenuItem-root {
	text-transform: capitalize !important;
}

.title {
	color: var(--primary-color);
	font-size: 2rem;
	font-family: var(--font-medium);
	margin: 0;
	padding: 0;
}

.add__modal {
	top: 10%;
	left: 30%;
	right: 30%;
	bottom: 15%;
	overflow: auto;
	min-width: max-content;
	background-color: aliceblue;
}
.add__modal__header {
	color: #1c1c1c;
	font-size: 1.5rem;
	font-family: var(--font-medium);
	margin: 0.1em 0;
	text-align: center;
}
.add__modal__content {
	min-width: 35rem;
	width: 80%;
	margin: 0 auto;
}

.file-input {
	display: none;
	margin: 0;
	padding: 0;
}

.cursor-pointer {
	cursor: pointer;
}

hr {
	background: var(--primary-color);
	margin: 1em 0;
	height: 1px;

	color: var(--primary-color);
}
