.subCategory {
	&__box {
		width: 100%;
		background-color: white;
		margin: 2em 0 0;
		border: 1px solid var(--primary-color);
		padding: 2em;
		min-height: 75vh;
		display: grid;
		overflow-y: visible;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 5rem;
		margin-bottom: 1em;

		& > button {
			background-color: var(--primary-color);
			padding: 0.7em 1.15em;
			font-size: 1.2rem;
			width: auto;
			color: white;
			font-family: var(--font-medium);
			border-radius: 0.5em;
			outline: none;
			border: 1px solid var(--primary-color);
			transition: var(--transition-bg), var(--transition-color);
			cursor: pointer;

			&:hover {
				color: var(--primary-color);
				background-color: white;
			}
		}
	}

	&__table {
		display: grid;
		grid-template-columns: 5% 20% 50% 15%;
		gap: 1em;
		width: 100%;
		margin-bottom: 1em;

		&-title {
			color: var(--primary-color);
			font-family: var(--font-medium);
			font-size: 1.1rem;
			place-self: flex-start;
			text-align: left;
		}

		&-item {
			color: #091434;
			font-family: var(--font-medium);
			font-size: 1rem;
			justify-self: flex-start;
			align-self: center;
			text-align: left;
			width: 90%;
			overflow-wrap: break-word;
		}
		&-chip-container{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 0px;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-self: center;
	}
}

.subCategory__modal {
	top: 10%;
	left: 10%;
	right: 10%;
	bottom: 10%;
	overflow: auto;
	min-width: max-content;
	background-color: #eff8f0;
	
	&__chip-container{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 30px;
	}
}
