.coupon {
	&__box {
		width: 100%;
		background-color: white;
		margin: 2em 0 0;
		border: 1px solid var(--primary-color);
		padding: 2em;
		min-height: 75vh;
		height: auto;
		display: grid;
		overflow-y: auto;
	}

	&__table {
		display: grid;
		grid-template-columns: 10% 20% 15% 50%;
		gap: 1em;
		width: 100%;
		margin-bottom: 1em;
	}

	&__modal {
		top: 20%;
		left: 30%;
		right: 30%;
		bottom: 20%;
		overflow: auto;
		min-width: max-content;
		background-color: #eff8f0;
	}

	&__header {
		display: flex;
		width: 50%;
		justify-content: space-between;
		align-items: center;

		& > label,
		& > button {
			background-color: var(--primary-color);
			padding: 0.7em 1.15em;
			font-size: 1.2rem;
			width: auto;
			color: white;
			font-family: var(--font-medium);
			border-radius: 0.5em;
			text-align: center;
			outline: none;
			border: 1px solid var(--primary-color);
			transition: var(--transition-bg), var(--transition-color);
			cursor: pointer;

			&:hover {
				color: var(--primary-color);
				background-color: white;
			}
		}
	}
}
