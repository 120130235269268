.home-page-rows{
    &__container{
        margin-bottom: 2em;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
    }
    &__table{
        display: grid;
        grid-template-columns: 5% 55% 15% 15%;
        gap: 1em;
        width: 100%;
        margin-bottom: 1em;

        &-title{
            color: var(--primary-color);
			font-family: var(--font-medium);
			font-size: 1.1rem;
			place-self: flex-start;
			text-align: left;
        }

        &-item{
            color: #091434;
			font-family: var(--font-medium);
			font-size: 1rem;
			justify-self: flex-start;
			align-self: center;
			text-align: left;
			width: 90%;
			overflow-wrap: break-word;

            &-actions{
                display: grid;
                grid-template-columns: 50% 50%;
            }
        }
    }
}