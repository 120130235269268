.pagination {
	place-self: flex-end;
	width: clamp(12rem, 20%, 10rem);
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__title {
		color: var(--primary-color);
		font-family: var(--font-medium);
		font-size: 1.1rem;
		place-self: center;
	}

	&__icon {
		width: 1rem;
	}


}
