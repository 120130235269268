.user {
	&__table {
		display: grid;
		grid-template-columns: 5% 15% 25% 25% 15% 5%;
		gap: 0.8em;
		width: 100%;
		margin-bottom: 1em;
	}

	&__box {
		width: 100%;
		background-color: white;
		margin: 2em 0 0;
		border: 1px solid var(--primary-color);
		padding: 2em;
		height: 80vh;
		display: grid;
		overflow-y: auto;
	}

	&__detail {
		&-id {
			font-size: 1.5rem;
			font-family: var(--font-medium);
		}

		&-title {
			font-size: 2rem;
			color: var(--primary-color);
			font-family: var(--font-medium);
		}

		&-label {
			font-size: 1rem;
			color: #676767;
			font-family: var(--font-medium);
			text-transform: capitalize;
			margin: 0 1em 2em 0;
		}

		&-value {
			font-size: 1.1rem;
			color: #363636;
			font-family: var(--font-medium);
		}
	}

	&__container {
		margin: 2em 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2em;
	}
}

.user__modal {
	top: 7%;
	left: 30%;
	right: 30%;
	bottom: 8%;
	overflow: auto;
	min-width: max-content;
	background-color: #eff8f0;
}

.address__modal {
	top: 7%;
	left: 20%;
	right: 20%;
	bottom: 7%;
	overflow: auto;
	min-width: max-content;
	background-color: #eff8f0;
}
