.deal {
	&__box {
		width: 100%;
		background-color: white;
		margin: 2em 0 0;
		border: 1px solid var(--primary-color);
		padding: 2em;
		display: grid;
		overflow-y: auto;
		min-height: 40vh;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 50%;
		height: 5rem;
		margin: 2em auto 0 auto;

		& > button {
			background-color: var(--primary-color);
			padding: 0.7em 1.15em;
			font-size: 1.2rem;
			width: auto;
			color: white;
			font-family: var(--font-medium);
			border-radius: 0.5em;
			outline: none;
			border: 1px solid var(--primary-color);
			transition: var(--transition-bg), var(--transition-color);
			cursor: pointer;

			&:hover {
				color: var(--primary-color);
				background-color: white;
			}
		}
	}

	&__table {
		display: grid;
		grid-template-columns: 15% 25% 50%;
		gap: 1em;
		width: 100%;
		margin-bottom: 1em;

		&-title {
			color: var(--primary-color);
			font-family: var(--font-medium);
			font-size: 1.1rem;
			place-self: flex-start;
			text-align: left;
		}

		&-item {
			color: #091434;
			font-family: var(--font-medium);
			font-size: 1rem;
			justify-self: flex-start;
			align-self: center;
			text-align: left;
			width: 90%;
			overflow-wrap: break-word;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-self: center;
	}
}

.season__modal {
	top: 20%;
	left: 30%;
	right: 30%;
	bottom: 35%;
	overflow: auto;
	min-width: max-content;
	background-color: #eff8f0;
}
