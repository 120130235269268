.item-view {
	margin-top: 2em;
	height: 100%;
}
.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.upload {
	display: grid;
	place-items: center;
	width: 100%;
	cursor: pointer;
	height: 7rem;
	background-color: #ffffff;
	box-shadow: var(--shadow);
}

.image-svg {
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	display: inline-block;
}

.image-upload {
	height: 7rem;
	/*max-height: 5rem;*/
	// width: 100%;
	object-fit: cover;
}
