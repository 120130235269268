.blog {
	&__main {
		padding: 2em 1em;
		width: 100%;
		height: 100%;
		grid-column: 2/3;
		overflow: auto;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 5rem;
		margin-bottom: 1em;

		& > button {
			background-color: var(--primary-color);
			padding: 0.7em 1.15em;
			font-size: 1.2rem;
			width: auto;
			color: white;
			font-family: var(--font-medium);
			border-radius: 0.5em;
			outline: none;
			border: 1px solid var(--primary-color);
			transition: var(--transition-bg), var(--transition-color);
			cursor: pointer;

			&:hover {
				color: var(--primary-color);
				background-color: white;
			}
		}
	}
}
