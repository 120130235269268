.wrapper-class {
	z-index: 100;
	width: 90%;
}
.editor-class {
	box-shadow: var(--shadow);
	padding: 1em;
}
.toolbar-class {
	box-shadow: var(--shadow);
	z-index: 100;
	padding: 1em;
}
