.product-type {
	&__main {
		padding: 2em 2em;
		width: 100%;
		height: 100vh;
		grid-column: 2/3;
		overflow-y: auto;
	}
	&__table {
		display: grid;
		grid-template-columns: 5% 15% 10% 15% 15% 15% 15%;
		gap: 1em;
		width: 100%;
		margin-bottom: 1em;
	}
}

.instruction {
	&__table {
		display: grid;
		grid-template-columns: 10% 20% 20% 45%;
		gap: 1em;
		width: 100%;
		margin-bottom: 1em;
	}

	&__modal {
		top: 15%;
		left: 30%;
		right: 30%;
		bottom: 15%;
		overflow: auto;
		min-width: max-content;
		background-color: #eff8f0;
	}

	&__detail {
		box-sizing: var(--shadow);
		background-color: #ffffff;
		border: 1px solid var(--primary-color);
		width: 90%;
		padding: 1em 2em;
		margin: 2em 0;
		cursor: pointer;
		height: auto;
		transition: height 1000 ease;
	}

	&__icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.product{
	&__table{
		display: grid;
        grid-template-columns: 5% 20% 50% 15%;
        gap: 1em;
        width: 100%;
        margin-bottom: 1em;

		&-title{
            color: var(--primary-color);
			font-family: var(--font-medium);
			font-size: 1.1rem;
			place-self: flex-start;
			text-align: left;
        }

        &-item{
            color: #091434;
			font-family: var(--font-medium);
			font-size: 1rem;
			justify-self: flex-start;
			align-self: flex-start;
			text-align: left;
			width: 90%;
			overflow-wrap: break-word;

            &-actions{
                display: grid;
                grid-template-columns: 50% 50%;
				align-self: flex-start;
            }

			&__chip{
				&-container{
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
				}
			}
        }
	}
}

.product-detail{
	&__chip{
		&-container{
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			margin-top: 30px;
		}
	}
}
