.nav {
	height: 100%;
	display: grid;
	grid-template-rows: 10% 80% 5%;
	grid-template-areas: "logo" "nav-items" "sign-out";
	background-color: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;

	&__logo {
		grid-area: logo;
		justify-self: flex-start;
		align-self: flex-start;
		&-image {
			width: 100%;
			max-width: 5rem;
			height: 100%;
			max-height: 5rem;
			padding-right: 2em;
		}
	}

	&__items {
		padding: 1em 0;
		grid-area: nav-items;
		display: flex;
		flex-direction: column;
		height: 80%;
		align-content: space-between;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__items a > p {
		text-decoration: none;
		font-family: var(--font-semi-jose);
		text-transform: uppercase;
		font-weight: 400;
		color: #8b8b8b;
		letter-spacing: 0.3em;
		font-size: 0.8rem;
		transition: color ease-in-out 200ms;
		outline: none;
		margin-top: 2em;

		&:hover {
			color: var(--primary-color);
		}
	}

	&__products > a > p {
		text-decoration: none;
		font-family: var(--font-semi-jose);
		text-transform: uppercase;
		font-weight: 400;
		color: #8b8b8b;
		letter-spacing: 0.3em;
		font-size: 0.7rem;
		transition: color ease-in-out 200ms;
		outline: none;
		margin-top: 1em;

		&:hover {
			color: var(--primary-color);
		}
	}

	&__items &__item-selected > p {
		color: var(--primary-color);
	}
	&__products &__item-selected > p {
		color: var(--primary-color);
	}

	&__sign-out {
		grid-area: sign-out;
		align-self: center;
		justify-self: flex-start;
		justify-content: flex-start;
		text-decoration: none;
		color: #8b8b8b;
		font-size: 1.2rem;
		transition: color ease 200ms;
		outline: none;
		cursor: pointer;
		font-family: var(--font-medium);

		&:hover {
			color: var(--primary-color);
		}
	}

	&__sub {
		margin-left: 1em;
	}
}
