.banner {
	&__one {
		display: grid;
		grid-template-columns: 0.4fr 0.6fr;
		gap: 2em;
		width: 100%;
		background-color: white;
		margin: 2em 0;
		border: 1px solid var(--primary-color);
		padding: 2em 1em;

		&-checkbox {
			display: flex;
			justify-content: space-around;
			margin-top: 2em;
		}

		&-footer {
			display: flex;
			justify-content: space-between;
			margin-top: 2em;
			padding: 0 1em;
		}
	}

	&__container {
		padding: 2em 1em 2em;
		margin-bottom: 2em;
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
	}
}

.banner__button {
	background-color: var(--primary-color);
	padding: 0.7em 1.15em;
	font-size: 1.2rem;
	width: auto;
	color: white;
	font-family: var(--font-medium);
	border-radius: 0.5em;
	outline: none;
	border: 1px solid var(--primary-color);
	transition: var(--transition-bg), var(--transition-color);
	cursor: pointer;

	&:hover {
		color: var(--primary-color);
		background-color: white;
	}
	&:disabled {
		opacity: 0.5;
		cursor: disabled;
	}
}
