.dashboard {
	display: grid;
	grid-template-columns: minmax(15rem, 20%) 80%;
	grid-template-areas: "nav main";
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;

	&__nav {
		grid-area: nav;
		margin: 0;
		padding: 2em 0 0 2em;
		height: 100vh;
		width: 100%;
		background-color: #ffffff;
		overflow: hidden;

		//box-shadow: var(--shadow);
	}

	&__main {
		grid-area: main;
		margin: 0;
		width: 100%;
		overflow: visible;
		padding: 0 0 2em;
		display: grid;
		grid-template-columns: minmax(1px, 1fr) minmax(50rem, 25fr) minmax(
				1px,
				1px
			);
		background-color: var(--secondary-color);
	}
}
